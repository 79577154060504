// src/components/services/courseService.ts
import { db } from '../../firebase-config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Course } from '../models/Course';

class CourseServiceClass {
  async fetchCoursesByCategory(category: string): Promise<Course[]> {
    try {
      const coursesRef = collection(db, "courses");
      const q = query(coursesRef, where("category", "==", category));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        ...doc.data() as Course,
        id: doc.id,
      }));
    } catch (error) {
      console.error(`Error fetching ${category} courses:`, error);
      return [];
    }
  }

  async fetchAllCourses(): Promise<Course[]> {
    try {
      // Fetch courses by category
      const beginnerCourses = await this.fetchCoursesByCategory("Beginner");
      const intermediateCourses = await this.fetchCoursesByCategory("Intermediate");
      const advancedCourses = await this.fetchCoursesByCategory("Advanced");

      // Combine all courses into a single array
      return [...beginnerCourses, ...intermediateCourses, ...advancedCourses];
    } catch (error) {
      console.error("Error fetching all courses:", error);
      return [];
    }
  }
}

const CourseService = new CourseServiceClass();
export default CourseService;
