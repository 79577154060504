import React, { useEffect, useState, ChangeEvent } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useSelectedUser } from '../../context/SelectedUserContext';
import { db } from '../../firebase-config';

interface User {
  uid: string;
  email: string;
  displayName: string;
}

const UserSelect: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const { selectedUser, setSelectedUser } = useSelectedUser();
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(getFirestore(), 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData = usersSnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() } as User));
      setUsers(usersData);
      setFilteredUsers(usersData);
    };

    fetchUsers();
  }, []);

  const handleUserChange = (event: SelectChangeEvent<string>) => {
    const selectedUserId = event.target.value;
    const user = users.find(u => u.uid === selectedUserId);
    setSelectedUser(user || null);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = users.filter(user =>
      (user.email?.toLowerCase().includes(query) || false) ||
      (user.displayName?.toLowerCase().includes(query) || false)
    );
    setFilteredUsers(filtered);
  };

  useEffect(() => {
    console.log("Search Query:", searchQuery);
    console.log("Filtered Users:", filteredUsers);
  }, [searchQuery, filteredUsers]);

  return (
    <div>
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchChange}
        margin="normal"
      />
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="user-select-label">Select User</InputLabel>
        <Select
          labelId="user-select-label"
          value={selectedUser?.uid || ''}
          onChange={handleUserChange}
          label="Select User"
        >
          {filteredUsers.map(user => (
            <MenuItem key={user.uid} value={user.uid}>
              {user.displayName ? user.displayName : user.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default UserSelect;