import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, getFirestore, where } from 'firebase/firestore';
import { Grid, Box, Card, CardMedia, CardContent, Typography, ThemeProvider } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { Bloc } from './models/Index';
import theme from '../theme';
import styles from './BlocListPage.module.css';

const CourseDetailsPage: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const [blocs, setBlocs] = useState<Bloc[]>([]);
  const { user } = useAuth();
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchLessons = async () => {
      if (courseId) {
        const lessonsQuery = query(collection(db, "blocs"), where("courseId", "==", courseId));
        const querySnapshot = await getDocs(lessonsQuery);
        const lessonsArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Bloc[];
        setBlocs(lessonsArray);
      }
    };

    fetchLessons();
  }, [courseId]);

  const handleItemClick = (bloc: Bloc, index: number) => {
    if (!user && index >= 3) {
      navigate('/signin');
    } else {
      navigate(`/bloc/${bloc.id}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={styles['grid-container']}>
        <Grid container spacing={2}>
          {blocs && blocs.map((bloc, index) => (
            <Grid item xs={12} sm={6} md={4} key={bloc.id} className={styles['grid-item']}>
              <Card onClick={() => handleItemClick(bloc, index)} sx={{ cursor: 'pointer', borderRadius: '12px', boxShadow: 3 }}>
              <CardMedia
                  component="img"
                  image={bloc.thumbnailUrl}
                  alt={bloc.title}
                  sx={{
                    height: '100%',  // Set a specific height to ensure the image displays
                    width: '100%',
                    objectFit: 'cover',  // Ensures the image covers the area without distortion
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                  }}
                />
               <CardContent sx={{ backgroundColor: '#FFE0B2', textAlign: 'center' }}>
                <Typography variant="h6" sx={{ color: '#FF7043', fontWeight: 'bold' }}>
                  {bloc.title}
                </Typography>
              </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default CourseDetailsPage;