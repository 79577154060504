import React, { useEffect, useState, useRef } from 'react';
import { List, ListItem, Typography, Box, Button } from '@mui/material';
import { db } from '../../firebase-config';
import { collection, getDocs, doc, getDoc, query, where, setDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './feedbackView.module.css';
import { useSelectedUser } from '../../context/SelectedUserContext';
import FeedbackPage from './FeedbackPage';
import Player from '@vimeo/player';

interface Feedback {
  id: string;
  userId: string;
  combinedBeat: string;
  feedback: { role: string, title: string; description: string; active: boolean }[];
}

interface FeedbackViewProps {

  blocId: string;
}

interface Notation {
  phrase: string;
  beat: string;
  innerBeat: string;
  sy: string;
}

const FeedbackView: React.FC<FeedbackViewProps> = ({  blocId }) => {
  const [feedbackList, setFeedbackList] = useState<Feedback[]>([]);
  const [studentVideoUrl, setStudentVideoUrl] = useState<string | null>(null);
  const { selectedUser } = useSelectedUser();
  const [blocNotation, setBlocNotation] = useState<Notation[]>([]);
  const navigate = useNavigate();
  const playerContainerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const [currentVideoTime, setCurrentVideoTime] = useState<number>(0);

  useEffect(() => {
    const fetchFeedback = async () => {
      console.log(blocId);

      const notationRef = doc(db, `blocs/${blocId}`);
      const notationSnap = await getDoc(notationRef);
      if (notationSnap.exists()) {
        setBlocNotation(notationSnap.data().notationArray || []);
      }

      if (blocId && selectedUser) {
        const feedbackRef = collection(db, 'feedback');
        const q = query(
          feedbackRef,
         
          where('blocId', '==', blocId),
          where('userId', '==', selectedUser.uid)
        );
        const feedbackSnap = await getDocs(q);
        const feedbackData = feedbackSnap.docs.map(doc => ({ ...doc.data(), id: doc.id } as Feedback));
        setFeedbackList(feedbackData);

        const videoRef = collection(db, `users/${selectedUser.uid}/videos`);
        const vq = query(
          videoRef,
       
          where('blocId', '==', blocId)
        );
        const videosSnap = await getDocs(vq);
        if (!videosSnap.empty) {
          const videoDoc = videosSnap.docs[0];
          setStudentVideoUrl(videoDoc.data().studentVideoUrl);
        }
      }
    };

    fetchFeedback();
  }, [ blocId, selectedUser]);

  useEffect(() => {
    if (studentVideoUrl && playerContainerRef.current) {
      const videoId = parseInt(studentVideoUrl); // Parse the video ID from the string URL
      if (!isNaN(videoId)) {
        playerRef.current = new Player(playerContainerRef.current, {
          id: videoId,
          width: 640, // Set width as a number
        });

        playerRef.current.on('timeupdate', ({ seconds }) => {
          setCurrentVideoTime(seconds);
        });
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (playerRef.current) {
        playerRef.current.getCurrentTime().then(currentTime => {
          if (event.key === 'ArrowRight') {
            playerRef.current?.setCurrentTime(currentTime + 0.2);
          } else if (event.key === 'ArrowLeft') {
            playerRef.current?.setCurrentTime(currentTime - 0.2);
          }
        });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [studentVideoUrl]);

  const handleBackClick = () => {
    navigate(`/bloc/${blocId}`);
  };

  const handleSaveTime = async (combinedBeat: string) => {
    if (!selectedUser?.uid) {
      console.error("Missing required parameters to save time.");
      return;
    }

    const feedbackDoc = doc(db, 'feedback', `${blocId}_${combinedBeat}_${selectedUser.uid}`);

    await setDoc(feedbackDoc, {
      blocId,
      combinedBeat,
      userId: selectedUser.uid,
      time: currentVideoTime, // Save the current video time
    }, { merge: true });

    console.log(`Time ${currentVideoTime} saved successfully for combinedBeat ${combinedBeat}`);
  };

  return (
    <div className={styles.container}>
      {selectedUser?.email}
      <div className={styles.feedbackAndPlayerContainer}>
        <div className={styles.feedbackContainer}>
          <List>
            {blocNotation && blocNotation.length > 0 ? (
              blocNotation.map((notation, index) => (
                <ListItem key={index} className={styles.listItem} style={{ display: 'block' }}>
                  <FeedbackPage 
                    blocId={blocId}
                    combinedBeat={`${notation.phrase}.${notation.beat}.${notation.innerBeat}`}
                    currentVideoTime={currentVideoTime}
                    onSaveTime={handleSaveTime}
                    player={playerRef.current} // Pass the player instance to FeedbackPage
                  />
                </ListItem>
              ))
            ) : (
              <Typography variant="body1" className={styles.beatText}>
                No notation data available
              </Typography>
            )}
          </List>
        </div>

        {studentVideoUrl ? (
          <div className={styles.playerContainer}>
            <Box className={styles.videoContainer}>
              <Box className={styles.videoBox} style={{ paddingBottom: '56.25%' }}>
                <div ref={playerContainerRef} />
              </Box>
            </Box>
          </div>
        ) : (
          <div className={styles.playerContainer}>
            <Box className={styles.videoContainer}>
              <Box className={styles.videoBox} style={{ paddingBottom: '56.25%' }}>
                <h1>THEY HAVEN'T UPLOADED A VIDEO YET</h1>
              </Box>
            </Box>
          </div>
        )}
      </div>
      <Button variant="contained" color="secondary" onClick={handleBackClick}>
        Back to Lesson
      </Button>
    </div>
  );
};

export default FeedbackView;

