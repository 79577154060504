import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Define a custom theme with teal colors
let theme = createTheme({
  palette: {
    primary: {
      main: '#009688', // Teal for primary actions
    },
    secondary: {
      main: '#80cbc4', // Teal for secondary accents
    },
    background: {
      default: '#e0f2f1', // Pale teal for the general background
      paper: '#ffffff', // Keeping paper as white for contrast
    },
    text: {
      primary: '#333333', // Dark gray for main text for readability
      secondary: '#757575', // A lighter gray for secondary text
    },
  },
  typography: {
    fontFamily: '"Lato", sans-serif', // Switching to Lato for a more elegant look
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      letterSpacing: '0.5px',
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24, // Preserving rounded buttons for a modern touch
          fontWeight: 'bold',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow to match original CSS
          transition: '0.3s',
          '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;