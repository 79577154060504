import React, { ReactElement } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import LessonDetailsPage from './components/LessonDetailsPage';
import CourseDetailsPage from './components/CourseDetailsPage';
import NavBar from './components/NavBar';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { AuthProvider, useAuth } from './context/AuthContext';
import { SelectedUserProvider } from './context/SelectedUserContext';
import TiersPage from './components/TiersPage';
import UserFeedbackPage from './components/UserFeedbackPage';
import FeedbackDetailsPage from './components/FeedbackDetailsPage';
import FeedbackPage from './components/admin/FeedbackPage';
import StudentVideoUpload from './components/StudentVideoUpload';
import StudentFeedbackPage from './components/StudentFeedbackPage';
import FeedbackPageLive from './components/FeedbackPageLive';
import AdminFeedbackPage from './components/admin/AdminFeedbackPage';
import QuizPage from './components/QuizPage';
import BlocsListPage from './components/BlocsListPage';

const PrivateRoute = ({ children }: { children: ReactElement }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/signin" replace />;
};

const PublicRoute = ({ children }: { children: ReactElement }) => {
  const { user } = useAuth();
  return !user ? children : <Navigate to="/home" replace />;
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SelectedUserProvider>
          <BrowserRouter>
            <NavBar />
            <div className="App">
              <Routes>
                <Route path="/" element={<BlocsListPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/signin" element={<PublicRoute><SignIn /></PublicRoute>} />
                <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />
                <Route path="/course/:courseId" element={<CourseDetailsPage />} />
                <Route path="/bloc/:blocId" element={<LessonDetailsPage />} />
                <Route path="/quiz/:courseId/:lessonId/:blocId" element={<QuizPage />} />
                <Route path="/feedback" element={<PrivateRoute><UserFeedbackPage /></PrivateRoute>} />
                <Route path="/feedback-details/:feedbackId/:userId" element={<PrivateRoute><FeedbackDetailsPage /></PrivateRoute>} />
                <Route path="/admin-feedback/:blocId" element={<PrivateRoute><AdminFeedbackPage /></PrivateRoute>} />
                <Route path="/feedback/:courseId/:lessonId/:blocId/:combinedBeat" 
                       element={
                         <PrivateRoute>
                           <FeedbackPage 
                             blocId={''} 
                             combinedBeat={''}
                             currentVideoTime={0} 
                             onSaveTime={() => {}} 
                             player={{}} // dummyPlayer logic removed as it's not necessary now
                           />
                         </PrivateRoute>
                       } 
                />
                <Route path="/student-feedback/:blocId" element={<PrivateRoute><StudentFeedbackPage /></PrivateRoute>} />
                <Route path="/upload/:courseId/:lessonId/:blocId" element={<PrivateRoute><StudentVideoUpload  blocId={''} /></PrivateRoute>} />
                <Route path="/feedback-live/:blocId/:combinedBeat" 
                       element={
                         <PrivateRoute>
                           <FeedbackPageLive 
                             blocId={''} 
                             combinedBeat={''} 
                             currentVideoTime={0} 
                             onSaveTime={(time: number) => {}} 
                           />
                         </PrivateRoute>
                       } 
                />
              </Routes>
            </div>
          </BrowserRouter>
        </SelectedUserProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
