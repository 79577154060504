import React, { useState } from 'react';
import { sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase-config';
import { Button, TextField, Box, Typography, Container, Link } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const googleProvider = new GoogleAuthProvider();

  const signIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      alert('Failed to sign in. Please check your credentials.');
      console.error(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/home');
    } catch (error) {
      alert('Failed to sign in with Google. Please try again.');
      console.error(error);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert('Please enter your email address to reset your password.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset link sent. Please check your email.');
    } catch (error) {
      alert('Failed to send password reset email. Please try again.');
      console.error(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h4" sx={{ marginBottom: 2, color: '#003D3D', fontSize: '2rem' }}>
          Welcome Back!
        </Typography>
        <Box component="form" onSubmit={signIn} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ backgroundColor: '#f0f0f0', borderRadius: 1, fontSize: '1.1rem' }}
            InputLabelProps={{ style: { fontSize: '1.1rem' } }} // Increase font size of the label
            InputProps={{ style: { fontSize: '1.1rem' } }} // Increase font size of the input text
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ backgroundColor: '#f0f0f0', borderRadius: 1, fontSize: '1.1rem' }}
            InputLabelProps={{ style: { fontSize: '1.1rem' } }} // Increase font size of the label
            InputProps={{ style: { fontSize: '1.1rem' } }} // Increase font size of the input text
          />
          <Link
            component="button"
            variant="body2"
            onClick={handleForgotPassword}
            sx={{ textAlign: 'right', display: 'block', mb: 2, color: '#FF6347', textDecoration: 'underline', fontSize: '1rem' }}
          >
            Forgot Password?
          </Link>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 1, mb: 2, backgroundColor: '#007777', color: 'white', fontWeight: 'bold', textTransform: 'none', fontSize: '1.1rem',
              '&:hover': {
                backgroundColor: '#005555',
              },
            }}
          >
            Sign In
          </Button>
          <Button
            onClick={signInWithGoogle}
            fullWidth
            variant="contained"
            sx={{
              mt: 1, mb: 2, backgroundColor: '#FF6347', color: 'white', fontWeight: 'bold', textTransform: 'none', fontSize: '1.1rem',
              '&:hover': {
                backgroundColor: '#FF4500',
              },
            }}
          >
            Sign in with Google
          </Button>
        </Box>
      </Box>
      <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', fontSize: '1.1rem' }}>
        Don't have an account?{' '}
        <Link component={RouterLink} to="/signup" variant="body2" sx={{ color: '#007777', textDecoration: 'underline', fontSize: '1.1rem' }}>
          Sign Up
        </Link>
      </Typography>
    </Container>
  );
};

export default SignIn;