import React from 'react';
import { Card, Typography, Button } from '@mui/material';

const LargeBlocCard: React.FC = () => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 77, 77, 0.01)', // Dark teal with 50% opacity for transparency
        color: 'white',
        // padding: 3,
        // boxShadow: 3,
        // '&:hover': { boxShadow: 6 },
      }}
    >
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
          marginBottom: 2,
          fontSize: '1.2rem',
        }}
      >
        Elevate Your Tango Journey
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          marginBottom: 4,
          fontSize: '1rem',
        }}
      >
        Unlock exclusive sequences and join a community of passionate dancers. Your next move awaits!
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#00A3A3',
          color: 'white',
          marginBottom: 2,
          width: '80%',
          padding: 1,
          '&:hover': {
            backgroundColor: '#009999',
          },
        }}
      >
        Sign Up or Sign In
      </Button>
      {/* <Button
        variant="contained"
        sx={{
          backgroundColor: '#FF7F50',
          color: 'white',
          width: '80%',
          padding: 1,
          '&:hover': {
            backgroundColor: '#FF6347',
          },
        }}
      >
        Subscribe Now
      </Button> */}
    </Card>
  );
};

export default LargeBlocCard;
