import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Box, Button, Typography } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import FeedbackViewLive from './FeedbackViewLive';
import StudentVideoUpload from './StudentVideoUpload';
import styles from './StudentFeedbackPageStyles.module.css';
import FeedbackApprovalChart from './FeedbackApprovalChart';

const StudentFeedbackPage: React.FC = () => {
  const { blocId } = useParams<{ blocId: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [studentVideoUrl, setStudentVideoUrl] = useState<string | null>(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchFeedbackAndVideo = async () => {
      if (!user || !blocId) return;

      const feedbackQuery = query(
        collection(db, 'feedback'),
        where('userId', '==', user.uid),
        where('blocId', '==', blocId)
      );

      const videoQuery = query(
        collection(db, 'users', user.uid, 'videos'),
        where('blocId', '==', blocId)
      );

      const [feedbackSnapshot, videoSnapshot] = await Promise.all([getDocs(feedbackQuery), getDocs(videoQuery)]);

      if (!videoSnapshot.empty) {
        const videoData = videoSnapshot.docs[0].data();
        setStudentVideoUrl(videoData.studentVideoUrl);
      }
    };

    fetchFeedbackAndVideo();
  }, [user, blocId, db]);

  const handleBackClick = () => {
    navigate(`/bloc/${blocId}`);
  };

  return (
    <Box className={styles.feedbackPageContainer}>
      <Box className={styles.headerContainer}>
        <Box className={styles.buttonGroup}>
          {/* <Button
            variant="outlined"
            sx={{
              marginBottom: '10px',
              textTransform: 'none',
            }}
            onClick={handleBackClick}
          >
            Back to Lesson
          </Button> */}
          <Box sx={{ marginLeft: '10px' }}>
            <StudentVideoUpload blocId={blocId || ''} />
          </Box>
        </Box>
      </Box>

      <Box className={styles.feedbackAndPlayerContainer}>
        <Box className={styles.feedbackContainer}>
          <FeedbackViewLive blocId={blocId || ''} />
        </Box>

        <Box className={styles.playerContainer}>
          {studentVideoUrl ? (
            <Box className={styles.videoContainer}>
              <Box className={styles.videoBox}>
                <iframe
                  src={`https://player.vimeo.com/video/${studentVideoUrl}`}
                  title="Vimeo video player"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 'none',
                  }}
                  allowFullScreen
                />
              </Box>
            </Box>
          ) : (
            <Box className={styles.videoContainer}>
              <Box className={styles.videoBox}>
                <Typography variant="h6" sx={{ color: '#D32F2F', textAlign: 'center', fontWeight: 'bold' }}>
                  No Video Uploaded Yet
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <FeedbackApprovalChart blocId={blocId || ''} />
      </Box>
    </Box>
  );
};

export default StudentFeedbackPage;
