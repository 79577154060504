import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel, FormGroup, Checkbox, Button, Typography } from '@mui/material';
import { db } from '../../firebase-config';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { useSelectedUser } from '../../context/SelectedUserContext';

interface FeedbackItem {
  title: string;
  description: string;
  active: boolean;
}

const initialFeedbackItems: FeedbackItem[] = [
    {
      "description": "Make sure your weight is fully transferred, with knees over toes or even beyond if you are flexing your knees. In all cases, whether front, side, or back.",
      "active": true,
      "title": "Leader: Transfer of Weight"
    },
    {
      "description": "Make sure your weight is fully transferred, with knees over toes or even beyond if you are flexing your knees. In all cases, whether front, side, or back.",
      "active": true,
      "title": "Follower: Transfer of Weight"
    },
    {
      "description": "Don't fall in the plié. Instead, trust away from the floor with the back muscles of the trailing leg pushing like a pedal through the ankle joint and extending the knee.",
      "active": true,
      "title": "Leader: Trust with the Standing Leg"
    },
    {
      "description": "Don't fall in the plié. Instead, trust away from the floor with the back muscles of the trailing leg pushing like a pedal through the ankle joint and extending the knee.",
      "active": true,
      "title": "Follower: Trust with the Standing Leg"
    },
    {
      "description": "The arrival should be a tombé with a controlled landing if the step is front or side, and an extension followed by a weight transfer if it's back.",
      "active": true,
      "title": "Leader: Proper strike of the leg"
    },
    {
      "description": "The arrival should be a tombé with a controlled landing if the step is front or side, and an extension followed by a weight transfer if it's back.",
      "active": true,
      "title": "Follower: Proper strike of the leg"
    },
    {
      "description": "45° if it's a cross front or back, 22.5° if it's sideways.",
      "active": true,
      "title": "Leader: Feet Turn Out"
    },
    {
      "description": "45° if it's a cross front or back, 22.5° if it's sideways.",
      "active": true,
      "title": "Follower: Feet Turn Out"
    },
    {
      "description": "Proper swing of the leg. Halfway from and to the center, from a turned out front, side, or back to a parallel knee, and vice versa.",
      "active": true,
      "title": "Leader: Proper Swing of the Leg"
    },
    {
      "description": "Proper swing of the leg. Halfway from and to the center, from a turned out front, side, or back to a parallel knee, and vice versa.",
      "active": true,
      "title": "Follower: Proper Swing of the Leg"
    },
    {
      "description": "Make sure the line between your feet is parallel to the line between your partner's feet.",
      "active": true,
      "title": "Leader: Parallel Lines"
    },
    {
      "description": "Make sure the line between your feet is parallel to the line between your partner's feet.",
      "active": true,
      "title": "Follower: Parallel Lines"
    }
];

interface FeedbackPageProps {

  blocId: string;
  combinedBeat: string;
  currentVideoTime: number;
  onSaveTime: (combinedBeat: string) => void;
  player: any;
}

const FeedbackPage: React.FC<FeedbackPageProps> = ({ blocId, combinedBeat, currentVideoTime, onSaveTime, player }) => {
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackItem[] | null>(null);
  const { isAdmin } = useAuth();
  const { selectedUser } = useSelectedUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate('/'); // Redirect to home if not admin
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    const fetchUserFeedback = async () => {
      if (selectedUser?.uid) {
        const feedbackDocRef = doc(
          db,
          'feedback',
          `${blocId}_${combinedBeat}_${selectedUser.uid}`
        );

        const feedbackSnapshot = await getDoc(feedbackDocRef);
        if (feedbackSnapshot.exists()) {
          const userFeedback = feedbackSnapshot.data().feedback;
          setSelectedFeedback(userFeedback);
        } else {
          // Save the initial feedbackItems if no document exists
          await setDoc(feedbackDocRef, {
            blocId,
            combinedBeat,
            userId: selectedUser.uid,
            feedback: initialFeedbackItems,
            time: currentVideoTime, // Initialize the time field with current video time
          });
          setSelectedFeedback(initialFeedbackItems);
        }
      }
    };

    fetchUserFeedback();
  }, [blocId, combinedBeat, selectedUser]);

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSelectedFeedback(prevFeedback =>
      prevFeedback?.map(item =>
        item.title === name ? { ...item, active: checked } : item
      ) || initialFeedbackItems
    );
  };

  const saveFeedback = async () => {
    if (!selectedUser?.uid) {
      console.error("Missing required parameters to save feedback.");
      return;
    }

    const feedbackDoc = doc(db, 'feedback', `${blocId}_${combinedBeat}_${selectedUser.uid}`);

    await setDoc(feedbackDoc, {
      blocId,
      combinedBeat,
      userId: selectedUser.uid,
      feedback: selectedFeedback || initialFeedbackItems,
      time: currentVideoTime, // Save the video time
    }, { merge: true });

    console.log('Feedback saved successfully');
  };

  const seekToTime = async () => {
    const feedbackDoc = doc(db, 'feedback', `$${blocId}_${combinedBeat}_${selectedUser?.uid}`);
    const feedbackSnapshot = await getDoc(feedbackDoc);
    if (feedbackSnapshot.exists()) {
      const time = feedbackSnapshot.data().time;
      if (player && time !== undefined) {
        player.setCurrentTime(time).catch((error: any) => {
          console.error('Error setting video time:', error);
        });
      }
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={seekToTime}>
        {combinedBeat}
      </Button>
      <FormGroup>
        {selectedFeedback && selectedFeedback.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={item.active}
                onChange={handleFeedbackChange}
                name={item.title}
              />
            }
            label={<><strong>{item.title}</strong></>}
          />
        ))}
      </FormGroup>
      <Button variant="contained" color="primary" onClick={saveFeedback}>
        Save Feedback
      </Button>
      <Button variant="outlined" color="secondary" onClick={() => onSaveTime(combinedBeat)}>
        Save Time
      </Button>
    </div>
  );
};

export default FeedbackPage;