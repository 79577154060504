import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, TextField, Box } from '@mui/material';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase-config';
import { doc, updateDoc } from 'firebase/firestore';
import styles from '../StudentVideoUpload.module.css';
import { useSelectedUser } from '../../context/SelectedUserContext';

interface AdminVideoUploadProps {

  blocId: string;
}

const AdminVideoUpload: React.FC<AdminVideoUploadProps> = ({ blocId }) => {
  const [file, setFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const { selectedUser } = useSelectedUser();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file || !selectedUser) return;

    console.log(selectedUser.uid);

    const storageRef = ref(storage, `videos/${blocId}_${selectedUser.uid}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', 
      () => {},
      (error) => {
        console.error('Upload failed:', error);
      }, 
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        const userDocRef = doc(db, 'users', selectedUser.uid, 'videos', `${blocId}`);
        await updateDoc(userDocRef, { studentVideoUrl: downloadURL });
        navigate(`/bloc/${blocId}`);
      }
    );
  };

  return (
    <div className={styles.container}>
      <Typography variant="body1" className={styles.instructionText}>
        Upload a video on behalf of {selectedUser?.displayName || selectedUser?.email} to receive personalized feedback from Andres.
      </Typography>
      <Box className={styles.uploadSection}>
        <TextField 
          type="file" 
          onChange={handleFileChange} 
          variant="outlined" 
          className={styles.fileInput}
        />
        <br/>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleUpload}
          className={styles.uploadButton}
        >
          Upload Video
        </Button>
      </Box>
    </div>
  );
};

export default AdminVideoUpload;