// src/components/AdminFeedbackPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import UserSelect from './UserSelect';
import FeedbackPage from './FeedbackPage';
import styles from './AdminFeedbackPageStyles.module.css';
import AdminVideoUpload from './AdminVideoUpload';
import FeedbackChartSeeAs from './FeedbackChartSeeAs';
import FeedbackView from './FeedbackView';

const AdminFeedbackPage: React.FC = () => {
  const { courseId, lessonId, blocId } = useParams<{ courseId: string; lessonId: string; blocId: string }>();

  return (
    <div className={styles.adminFeedbackPageContainer}>
      <UserSelect />
     
      <FeedbackView  blocId={blocId || ''} />
      <AdminVideoUpload  blocId={blocId || ''} />
      <FeedbackChartSeeAs  blocId={blocId || ''}  />
    </div>
  );
};

export default AdminFeedbackPage;