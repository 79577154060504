import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import { useAuth } from '../context/AuthContext';
import { Box, Typography, useTheme } from '@mui/material';
import { teal, blue } from '@mui/material/colors';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface FeedbackItem {
  title: string;
  description: string;
  active: boolean;
}

interface Feedback {
  id: string;
  userId: string;
  combinedBeat: string;
  feedback: FeedbackItem[];
}

interface FeedbackApprovalChartProps {
  blocId: string;
}

const FeedbackApprovalChart: React.FC<FeedbackApprovalChartProps> = ({ blocId }) => {
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    datasets: [
      {
        label: 'Approval Percentage',
        data: [] as number[],
        backgroundColor: [] as string[],
        borderColor: [] as string[],
        borderWidth: 1,
      },
    ],
  });

  const { user } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    const fetchFeedbackData = async () => {
      if (!user) return;

      const feedbackRef = collection(db, 'feedback');
      const feedbackQuery = query(
        feedbackRef,
        where('blocId', '==', blocId),
        where('userId', '==', user.uid)
      );

      const feedbackSnapshot = await getDocs(feedbackQuery);
      const feedbackData = feedbackSnapshot.docs.map(doc => doc.data() as Feedback);

      const parameterCount: { [title: string]: { total: number; approved: number } } = {};

      feedbackData.forEach(feedback => {
        if (feedback.feedback) { // Check if feedback.feedback is defined
          feedback.feedback.forEach(item => {
            if (!parameterCount[item.title]) {
              parameterCount[item.title] = { total: 0, approved: 0 };
            }
            parameterCount[item.title].total += 1;
            if (!item.active) { // Inverted logic: active false means approved
              parameterCount[item.title].approved += 1;
            }
          });
        }
      });

      const labels = Object.keys(parameterCount);
      const data = labels.map(label => {
        const { total, approved } = parameterCount[label];
        return (approved / total) * 100;
      });
      const backgroundColor = labels.map(label =>
        label.startsWith('Follower') ? "#1769aa" : teal[500]
      );
      const borderColor = labels.map(label =>
        label.startsWith('Follower') ? blue[700] : teal[700]
      );

      setChartData({
        labels,
        datasets: [
          {
            label: 'Approval Percentage',
            data,
            backgroundColor,
            borderColor,
            borderWidth: 1,
          },
        ],
      });
    };

    fetchFeedbackData();
  }, [blocId, user]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        boxShadow: 3,
        padding: 3,
        marginTop: 4,
      }}
    >
      <Typography 
        variant="h4" 
        sx={{ 
          color: 'black', 
          fontSize: '2rem', 
          paddingLeft: '1rem', 
          marginBottom: '1rem' 
        }}
      >
        Feedback Progress Dashboard
      </Typography>
      <Bar data={chartData} />
    </Box>
  );
};

export default FeedbackApprovalChart;