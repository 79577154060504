import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CourseService from './services/courseService';
import { Course } from './models/Course';
import { useAuth } from '../context/AuthContext';
import { Card, CardContent, Typography, CardActions, Button, Box, ThemeProvider, Grid, CardMedia } from '@mui/material';
import theme from '../theme';

const HomePage = () => {
  const { user } = useAuth();
  const [courseList, setCourseList] = useState<Course[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      const allCourses = await CourseService.fetchAllCourses();
      setCourseList(allCourses);
    };
    fetchCourses();
  }, [user]);

  const handleCourseClick = (course: Course) => {
    navigate(`/course/${course.id}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          padding: '40px',
          margin: '20px auto',
          maxWidth: '1200px',
          bgcolor: '#f0f4f8',
          borderRadius: '8px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginBottom: '40px',
            color: theme.palette.primary.main,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Explore Our Courses
        </Typography>
        {courseList.length > 0 ? (
          <Grid container spacing={4}>
            {courseList.map((course, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    borderRadius: '12px',
                    backgroundColor: '#ffffff',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={course.thumbnailUrl || 'https://via.placeholder.com/300x140'}
                    alt={course.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h5" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
                      {course.title}
                    </Typography>
                    {course.createdAt && (
                      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, marginTop: '8px' }}>
                        Added on: {new Date(course.createdAt.seconds * 1000).toLocaleDateString()}
                      </Typography>
                    )}
                    <Typography variant="body1" sx={{ marginTop: '15px', color: theme.palette.text.primary }}>
                      {course.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ padding: '16px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        marginLeft: 'auto',
                        padding: '10px 20px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }}
                      onClick={() => handleCourseClick(course)}
                    >
                      View Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography sx={{ color: theme.palette.text.secondary, textAlign: 'center', marginTop: '50px' }}>
            No courses found.
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default HomePage;
