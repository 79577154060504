import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { onAuthStateChanged, User, signOut as firebaseSignOut } from 'firebase/auth';
import { auth, db } from '../firebase-config'; // Make sure this points to your Firebase config
import { doc, getDoc } from 'firebase/firestore';

interface AuthContextType {
  user: User | null;
  isAdmin: boolean;
  isSubscribed: boolean; // New property to track subscription status
  selectedUser: User | null;
  setSelectedUser: (user: User | null) => void;
  signOut: () => Promise<void>; // Add signOut method to context
}

interface AuthProviderProps {
  children: ReactNode; // This type accepts any valid React child (e.g., JSX, strings, elements, etc.)
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAdmin: false,
  isSubscribed: false, // Default to false
  selectedUser: null,
  setSelectedUser: () => {},
  signOut: async () => {}, // Default to an empty async function
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false); // New state for subscription status
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        if (user.uid === "8dy0t60qvKZlTGfe8LkNYpFXkRq2") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
          setSelectedUser(null); // Clear the selected user when admin logs out
        }

        // Check subscription status
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsSubscribed(!!userData.isSubscribed); // Set subscription status
        } else {
          setIsSubscribed(false);
        }
      } else {
        setIsAdmin(false);
        setIsSubscribed(false);
        setSelectedUser(null); // Clear selected user when logged out
      }
    });

    return unsubscribe; // Clean up the subscription
  }, []);

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setCurrentUser(null);
      setIsAdmin(false);
      setIsSubscribed(false);
      setSelectedUser(null);
    } catch (error) {
      console.error("Failed to sign out:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user: currentUser, isAdmin, isSubscribed, selectedUser, setSelectedUser, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);
