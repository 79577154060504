import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useSelectedUser } from '../../context/SelectedUserContext';
import { Box, Typography, useTheme } from '@mui/material';
import { teal } from '@mui/material/colors';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface FeedbackItem {
  title: string;
  description: string;
  active: boolean;
}

interface Feedback {
  id: string;
  userId: string;
  combinedBeat: string;
  feedback: FeedbackItem[];
}

interface FeedbackApprovalChartProps {
  blocId: string;
}

const FeedbackChartSeeAs: React.FC<FeedbackApprovalChartProps> = ({  blocId }) => {
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    datasets: [
      {
        label: 'Approval Percentage',
        data: [] as number[],
        backgroundColor: teal[500],
        borderColor: teal[700],
        borderWidth: 1,
      },
    ],
  });

  const { selectedUser } = useSelectedUser();
  const theme = useTheme();

  useEffect(() => {
    const fetchFeedbackData = async () => {
      if (!selectedUser) return;

      const feedbackRef = collection(db, 'feedback');
      const feedbackQuery = query(
        feedbackRef,
      
        where('blocId', '==', blocId),
        where('userId', '==', selectedUser.uid)
      );

      const feedbackSnapshot = await getDocs(feedbackQuery);
      const feedbackData = feedbackSnapshot.docs.map(doc => doc.data() as Feedback);

      if (!feedbackData) return; // Add a check to ensure feedbackData is defined

      const parameterCount: { [title: string]: { total: number; approved: number } } = {};

      feedbackData.forEach(feedback => {
        feedback.feedback?.forEach(item => { // Add optional chaining to handle undefined feedback arrays
          if (!parameterCount[item.title]) {
            parameterCount[item.title] = { total: 0, approved: 0 };
          }
          parameterCount[item.title].total += 1;
          if (!item.active) { // Inverted logic: active false means approved
            parameterCount[item.title].approved += 1;
          }
        });
      });

      const labels = Object.keys(parameterCount);
      const data = labels.map(label => {
        const { total, approved } = parameterCount[label];
        return (approved / total) * 100;
      });

      setChartData({
        labels,
        datasets: [
          {
            label: 'Approval Percentage',
            data,
            backgroundColor: teal[500],
            borderColor: teal[700],
            borderWidth: 1,
          },
        ],
      });
    };

    fetchFeedbackData();
  }, [ blocId, selectedUser]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        boxShadow: 3,
        padding: 3,
        marginTop: 4,
      }}
    >
      <Typography 
        variant="h4" 
        sx={{ 
          color: 'black', 
          fontSize: '2rem', 
          paddingLeft: '1rem', 
          marginBottom: '1rem' 
        }}
      >
        Feedback Progress Dashboard
      </Typography>
      <Bar data={chartData} />
    </Box>
  );
};

export default FeedbackChartSeeAs;
