import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDoc, getFirestore, doc } from 'firebase/firestore';
import BlocItem from './BlocItem'; // Import BlocItem component
import { Box, Card } from '@mui/material';
import { Bloc } from './models/Index'; // Ensure correct interface import

// Define the expected structure of useParams
interface RouteParams {
  courseId: string;
  blocId: string;
}

const LessonDetailsPage: React.FC = () => {
  const { courseId, blocId } = useParams<{ courseId: string; blocId: string }>(); // Typing useParams to match expected structure
  const [bloc, setBloc] = useState<Bloc | null>(null); // Single bloc state
  const db = getFirestore();

  useEffect(() => {
    const fetchBloc = async () => {
      if (blocId) {
        try {
          // Reference to the specific document in the 'blocs' collection using the blocId
          const blocRef = doc(db, 'blocs', blocId);
    
          // Fetch the document
          const blocDoc = await getDoc(blocRef);
    
          // Check if the document exists
          if (blocDoc.exists()) {
            const blocData = { ...blocDoc.data(), id: blocDoc.id } as Bloc;
            setBloc(blocData);
          } else {
            console.error('No matching bloc found.');
          }
        } catch (error) {
          console.error('Error fetching bloc:', error);
        }
      }
    };

    fetchBloc();
  }, [blocId]);

  return (
    <Box sx={{ padding: '0px' }}>
      <Card style={{ backgroundColor: '#009688' }}>
        {bloc && (
          <BlocItem
            key={bloc.id}
            bloc={bloc}
            index={0}
            courseId={courseId || ''}
            blocId={blocId || ''}
          />
        )}
      </Card>
    </Box>
  );
};

export default LessonDetailsPage;
